import * as qs from 'qs';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Container, Grid } from '@material-ui/core';
import '../../styles/pages/login.scss';
import LoginForm from '../../components/login/LoginForm';
import Loader from '../../components/loader/Loader';
import Toaster from '../../components/alerts/Toaster';
import LandingTemplate from '../shared/LandingTemplate';
import { authenticationService } from '../../services/authentication.service';
import { macroRiskAnalysisFolderService } from '../../services/macroRiskAnalysisFolder.service';
import { ONBOARDING_BASE_URL, getEnv } from '../../env';

export const CommercePrequalificationStartPage = (props) => {
    const [loading, setLoading] = useState(false);
    const [commerceId, setCommerceId] = useState(null);
    const [redirectUrl, setRedirectUrl] = useState(null);
    const [toast, setToast] = useState(null);
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        if (props.location.search) {
            const { commerceId, redirectUrl } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
            setCommerceId(commerceId);
            setRedirectUrl(redirectUrl);
        }
    }, []);

    const getFirstName = (customerData) => {
        return customerData.customerFirstName ? customerData.customerFirstName : customerData.customerFullName.split(' ')[1];
    };

    const getLastName = (customerData) => {
        return customerData.customerLastName ? customerData.customerLastName : customerData.customerFullName.split(' ')[0];
    };

    const buildEvaluateMacroPublicRiskAnalysisFolder = (customerData, commerceId, businessOriginationUrl) => {
        return {
            customerId: customerData.idNumber,
            customerTaxId: customerData.taxId,
            customerFullName: customerData.customerFullName,
            customerFirstName: getFirstName(customerData),
            customerLastName: getLastName(customerData),
            customerBirthdate: customerData.birthday ? format(customerData.birthday, 'yyyy-MM-dd') : null,
            customerEmailAddress: customerData.email,
            customerCellphoneNumber: customerData.cellphone,
            financialProductType: ['LOAN'],
            businessOriginationUrl: businessOriginationUrl,
            commerceId: commerceId,
        };
    };

    const evaluateMacroPublicRiskAnalysisFolder = async (customerData, recaptchaToken) => {
        setLoading(true);
        const authenticatePayload = {
            cellphone: customerData.cellphone,
            pin: customerData.cellphonePin,
            recaptchaToken: recaptchaToken,
        };
        try {
            const authResult = await authenticationService.authenticate(authenticatePayload);
            localStorage.setItem('access_token', authResult.data.access_token);
            localStorage.setItem('refresh_token', authResult.data.refresh_token);
        } catch (error) {
            localStorage.clear();
            if (error.response) {
                const { cause, errorCode } = error.response.data;
                if (errorCode === 'UNAUTHORIZED') {
                    setToast({ message: t(`WORKFLOW_ERROR.${cause}.DESCRIPTION`) });
                    return;
                }
            }
            setToast({ message: t(`services.authentication.errors.generic`) });
            return;
        } finally {
            setLoading(false);
        }
        try {
            setLoading(true);
            const macroRiskAnalysisFolderRequestDto = buildEvaluateMacroPublicRiskAnalysisFolder(
                customerData,
                commerceId,
                getEnv(ONBOARDING_BASE_URL) || window.location.origin
            );
            const macroRiskAnalysisFolderResponseDto = (
                await macroRiskAnalysisFolderService.evaluateMacroPublicRiskAnalysisFolder(macroRiskAnalysisFolderRequestDto)
            ).data;
            if (macroRiskAnalysisFolderResponseDto.status === 'APPROVED') {
                history.push({
                    pathname: '/commerce-prequalifications/approved',
                    state: {
                        customerData: customerData,
                        prequalificationData: macroRiskAnalysisFolderResponseDto,
                        redirectUrl: redirectUrl,
                    },
                });
            } else {
                history.push({
                    pathname: '/commerce-prequalifications/rejected',
                    state: {
                        redirectUrl: redirectUrl,
                    },
                });
            }
        } catch (error) {
            localStorage.clear();
            setToast({ message: t(`services.macro-risk-analysis-folder-service.errors.generic`) });
        } finally {
            setLoading(false);
        }
    };

    return (
        <LandingTemplate {...props}>
            <Loader loading={loading} />
            <Toaster toast={toast} />
            {commerceId && (
                <Container maxWidth="sm">
                    <Grid container>
                        <Grid item xs={false} sm={1}></Grid>
                        <Grid item xs={12} sm={10} container>
                            <LoginForm
                                onSubmit={evaluateMacroPublicRiskAnalysisFolder}
                                disabled={loading}
                                translationSuffix="commerce-prequalifications"></LoginForm>
                        </Grid>
                        <Grid item xs={false} sm={1}></Grid>
                    </Grid>
                </Container>
            )}
        </LandingTemplate>
    );
};
